import React from 'react';
import $ from 'jquery';

const ReactMarkdown = require('react-markdown');

export default class GridAreaC extends React.Component {

    state = {
        text: this.props.fields.text? this.props.fields.text : "",
        images: this.props.fields.images? this.props.fields.images : "",
        textColour: this.props.fields.textColour? this.props.fields.textColour : "black",
        backgroundColour: this.props.fields.backgroundColour? this.props.fields.backgroundColour : "white",
        reverse: this.props.fields.reverseOrder? (this.props.fields.reverseOrder==="Yes"? true : false) : false,
        slideshowInterval: this.props.fields.slideshowInterval ? this.props.fields.slideshowInterval*1000 : 0,
        textWeight: this.props.fields.textWeight ? this.props.fields.textWeight : '400'
    }

    componentDidMount(){
        if (this.state.slideshowInterval !== 0) {
            this.setState({
                images: this.state.images.map((image) => {
                    return image.fields.file.url
                })
            })

            setInterval(() => {
                if(this.state.images.length > 1){
                    let first = $('.image-container').children().first();
                    first.hide().remove();
                    $('.image-container').append(first).children().last().fadeIn('slow');
                }
            }, this.state.slideshowInterval)
        }
        else {
            this.setState({
                images: [this.state.images[0].fields.file.url]
            })
        }

        if ($(window).width() <= 1024) {
            $('.gridarea-c .image-container').height( $('.gridarea-c .image-container').width() );
            $('.gridarea-c .text-container').css('max-height', $('.gridarea-c .text-container').width()+'px');
        }
        else {
            $('.gridarea-c .image-container').height( $('.gridarea-c .image-container').width()*0.5 );
        }
    }

    render() {
        return (
            <div className = "row gridarea-c">
                <div className = "text-container">
                    <div className = {this.state.reverse ? "col-4 offset-6 text-box" : "col-4 offset-2 text-box"} style = {{
                        backgroundColor: this.state.backgroundColour,
                        color: this.state.textColour,
                        fontWeight: this.state.textWeight
                    }}>
                        <ReactMarkdown source={this.state.text} escapeHtml={false} />
                    </div>
                </div>
                <div className="image-container" style = {this.state.reverse ? {justifyContent: "flex-start"} : {justifyContent: "flex-end"}}>
                    {
                        this.state.images.map((image, i) => {
                            return <img src={image} alt={"Grid Image "+i} key={i} />
                        })
                    }
                </div>
            </div>
        )
    }
}
