import React from 'react';
import $ from 'jquery';

const ReactMarkdown = require('react-markdown');

export default class TabbedAreaB extends React.Component{

    state = {
        backgroundColour: this.props.fields.backgroundColour ? this.props.fields.backgroundColour : 'white',
        textColour: this.props.fields.textColour ? this.props.fields.textColour : 'black',
        tabColour: this.props.fields.tabColour ? this.props.fields.tabColour : 'grey',
        tabs: this.props.fields.tabTitles ? (
            this.props.fields.tabTitles.length > 8 ?
                this.props.fields.tabTitles.slice(0,8) : this.props.fields.tabTitles
            ) : [],
        tabTexts: [
                (this.props.fields.tab1Text ? this.props.fields.tab1Text : '') ,
                (this.props.fields.tab2Text ? this.props.fields.tab2Text : '') ,
                (this.props.fields.tab3Text ? this.props.fields.tab3Text : '') ,
                (this.props.fields.tab4Text ? this.props.fields.tab4Text : '') ,
                (this.props.fields.tab5Text ? this.props.fields.tab5Text : '') ,
                (this.props.fields.tab6Text ? this.props.fields.tab6Text : '') ,
                (this.props.fields.tab7Text ? this.props.fields.tab7Text : '') ,
                (this.props.fields.tab8Text ? this.props.fields.tab8Text : '')
        ],
        textAlign: this.props.fields.textAlign ? this.props.fields.textAlign : 'center',
        currentTab: 0,
        tabTitleWeight: this.props.fields.tabTitleWeight? this.props.fields.tabTitleWeight : ' 600',
        textWeight: this.props.fields.textWeight? this.props.fields.textWeight : '200'
    }

    componentDidMount(){
        $('.tab').click((e)=>{
            this.setState({
                currentTab: $(e.currentTarget).prevAll().length
            });
            $(".tab").css("background-color", this.state.tabColour);
            $(e.currentTarget).css("background-color", this.state.backgroundColour);
        })
    }

    render(){
        return(
            <div className="row tabbed-area-b">
                <div className="col-12 tabs" style={{color: this.state.textColour}}>
                {
                    this.state.tabs.map((tab, i) => {
                        return <div className="tab" key={i} style={{
                            width: (100/this.state.tabs.length)+"%",
                            backgroundColor: i===this.state.currentTab ?
                                this.state.backgroundColour : this.state.tabColour,
                            fontWeight: i===this.state.currentTab ?
                                'bold' : this.state.tabTitleWeight,
                        }}> {tab} </div>
                    })
                }
                </div>
                <div className="col-12 text" style={{
                    textAlign: this.state.textAlign,
                    backgroundColor: this.state.backgroundColour,
                    color: this.state.textColour,
                    fontWeight: this.state.textWeight
                }}>
                    <ReactMarkdown escapeHtml={false} source={this.state.tabTexts[this.state.currentTab]}/>
                </div>
            </div>
        );
    }
}
