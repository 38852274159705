import React from "react";
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

export default class NavigationBarC extends React.Component{

    state = {
        text: this.props.fields.text ? this.props.fields.text : '',
        links: this.props.fields.links ? this.props.fields.links : '',
        image: this.props.fields.image ? this.props.fields.image.fields.file.url : '',
        textColour: this.props.fields.textColour ? this.props.fields.textColour : 'black',
        backgroundColour: this.props.fields.backgroundColour ? this.props.fields.backgroundColour : 'white',
        floatOnContent: this.props.fields.floatOnTopOfContent ? (
            this.props.fields.floatOnTopOfContent==='Yes' ? true : false
        ) : true,
        menuOpen: false,
        menuItemWeight: this.props.fields.menuItemWeight ? this.props.fields.menuItemWeight : '200'
    }

    componentDidMount(){
        $(document).scroll((e) => {
            if (!($(window).width() <= 1024)) {
                if($(window).scrollTop() === 0){
                    $('#navC-logo').hide();
                    $('#navC-logo').prependTo('.top').show();
                    $('.top').fadeIn('fast');
                    $('.bottom').css('justify-content','');
                    $('#navC-logo').css('margin-right', '');
                }else{
                    $('.top').slideUp();
                    $('#navC-logo').hide();
                    $('#navC-logo').prependTo('.bottom').show();
                    $('.bottom').css('justify-content','flex-end');
                    $('#navC-logo').css('margin-right', 'auto');
                }
            }
        })
    }

    menuClicked = () => {
        this.setState({
            menuOpen: !this.state.menuOpen
        })

        if (this.state.menuOpen) {
            $('.menu-panel').slideUp('swing');
        }
        else {
            $('.menu-panel').slideDown('swing');
        }
    }

    render(){
        return(
            <div className="row navbar-c" style={{ backgroundColor: this.state.backgroundColour }}>
                {
                    $(window).width() > 1024 && [
                        <div className="col-10 offset-1 top" key={1}>
                            <img id='navC-logo' src={this.state.image} alt="Logo" onClick={()=>window.location='/'} />
                        </div>,
                        <div className="col-10 offset-1 bottom" style={{ color: this.state.textColour }} key={2}>
                            {
                                this.state.text.map((text, i) => {
                                    if (i <= 9 && this.state.links[i]) {
                                        return (
                                            <div className="col-1 text-menu" style={{fontWeight: this.state.menuItemWeight}} key={i}>
                                                <a href={this.state.links[i]}>
                                                    {text}
                                                </a>
                                            </div>
                                        )
                                    }
                                    return ""
                                })
                            }
                        </div>
                    ]
                }
                {
                    $(window).width() <= 1024 && [
                        <div className="col-6 offset-3 top">
                            <img id='navC-logo' src={this.state.image} alt="Logo" onClick={()=>window.location='/'} />
                        </div>,
                        <div className="col-1 offset-1 arrow" onClick={this.menuClicked} style={{ color: this.state.textColour }}>
                            {this.state.menuOpen ? <FontAwesomeIcon icon={faChevronUp}/> : <FontAwesomeIcon icon={faChevronDown}/>}
                        </div>,
                        <div className="col-10 offset-1 bottom" style={{ color: this.state.textColour }}>
                            <div style={{display: 'none', backgroundColor: this.state.backgroundColour}} className="menu-panel">
                                {
                                    this.state.text.map((text, i) => {
                                        if (i <= 9 && this.state.links[i] && this.state.text.length !== i) {
                                            return <div className="col-10 offset-1 link" style={{fontWeight: this.state.menuItemWeight}} key={i}>
                                                <a href={this.state.links[i]}> {text} </a>
                                            </div>
                                        }
                                        return ""
                                    })
                                }
                            </div>
                        </div>
                    ]
                }
            </div>
        );
    }
}
