import React from "react";
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

export default class NavigationBarB extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            linkColour: props.fields.linkColour ?
                props.fields.linkColour : '',
            logoImage: props.fields.logo ?
                props.fields.logo.fields.file.url : '',
            linkText: props.fields.linkText ?
                props.fields.linkText : [],
            linkUrl: props.fields.linkUrl ?
                props.fields.linkUrl : [],
            transp: props.fields.transparentBeforeScroll ?
                ( props.fields.transparentBeforeScroll==="Yes" ?
                    true : false
                ) : false,
            backgroundColour: props.fields.backgroundColour ?
                (props.fields.transparentBeforeScroll==="Yes" ?
                    'transparent' : props.fields.backgroundColour
                ) : '',
            menuOpen: false,
            menuItemWeight: props.fields.menuItemWeight?
                props.fields.menuItemWeight : '200'
        }
    }

    componentDidMount(){
        $(document).scroll((e) => {
            if(this.state.transp && !this.state.menuOpen){
                if($(window).scrollTop() === 0){
                    this.setState({
                        backgroundColour: 'transparent'
                    })
                }else{
                    this.setState({
                        backgroundColour: this.props.fields.backgroundColour ?
                            this.props.fields.backgroundColour : ''
                    })
                }
            }
        })
    }

    menuClicked = () => {
        this.setState({
            menuOpen: !this.state.menuOpen
        })

        let bgc = this.props.fields.backgroundColour ?
            this.props.fields.backgroundColour : '';

        if(this.state.menuOpen){
            $('.navbar-b').css('background-color', this.state.backgroundColour);
            $('.menu-panel').slideUp('fast');
        }else{
            $('.navbar-b').css('background-color', bgc);
            $('.menu-panel').slideDown('fast');
        }
    }

    render(){
        let logoColumn = <div onClick={() => window.location.pathname='/'} className="col-2 logo" style={{backgroundImage: "url(" + this.state.logoImage +")"}} key={1}></div>;
        return(
            <div className="row navbar-b" style={{backgroundColor: this.state.backgroundColour, color: this.state.linkColour}}>
                {
                    $(window).width() > 1024 &&
                    this.state.linkText.map((link, i) => {
                        return [i === Math.floor(this.state.linkText.length/2) ?
                            logoColumn : '',
                            <div className="col-1 links" key={i} style={{
                                fontWeight: this.state.menuItemWeight
                            }}>
                                <a href={this.state.linkUrl[i]}> {link} </a>
                            </div>
                        ]
                    })
                }
                {
                    $(window).width() <= 1024 && [
                        <div className="offset-1 col-2 placeholder"></div>,
                        <div onClick={() => window.location.pathname='/'} className="col-6 logo" style={{backgroundImage: "url(" + this.state.logoImage +")"}} ></div>,
                        <div className="offset-1 col-2 menu" onClick={this.menuClicked}>
                            {this.state.menuOpen ? <FontAwesomeIcon icon={faChevronUp}/> : <FontAwesomeIcon icon={faChevronDown}/>}
                        </div>,
                        <div style={{display: 'none'}} className="col-12 menu-panel">
                            {
                                this.state.linkText.map((link, i) => {
                                    return <div className="links" key={i} style={{
                                        fontWeight: this.state.menuItemWeight
                                    }}>
                                        <a href={this.state.linkUrl[i]}> {link} </a>
                                    </div>
                                })
                            }
                        </div>
                    ]
                }
            </div>
        );
    }
}
