import React from 'react';

const ReactMarkdown = require('react-markdown');

export default class FooterC extends React.Component {

    state = {
        linkText: this.props.fields.linkText ?  this.props.fields.linkText : '',
        linkUrl: this.props.fields.linkUrl ? this.props.fields.linkUrl : '',
        logo: this.props.fields.logo.fields.file.url ? this.props.fields.logo.fields.file.url : '',
        textColour: this.props.fields.textColour ? this.props.fields.textColour : '',
        backgroundColour: this.props.fields.backgroundColour ? this.props.fields.backgroundColour : '',
        copyright: this.props.fields.copyright ? this.props.fields.copyright : '',
        textWeight: this.props.fields.textWeight ? this.props.fields.textWeight : '400'
    }

    render() {
        return (
            <div className="row footer-c" style={{ backgroundColor: this.state.backgroundColour }}>
                <div className="col-12 links" style={{ color: this.state.textColour, fontWeight: this.state.textWeight }}>
                    {
                        this.props.fields.linkText.map((text, i) => {
                            if(i < 8 && this.props.fields.linkUrl[i]){
                                return (
                                        <a className = "col-1" href={this.props.fields.linkUrl[i]} key={i}>
                                            {text}
                                        </a>
                                )
                            }
                            return ""
                        })
                    }
                </div>
                <div className="col-12 logo">
                    <a href="/">
                        <img src={this.state.logo} alt="Logo" />
                    </a>
                </div>
                <div className="col-12 copyright" style={{ color: this.state.textColour, fontWeight: this.state.textWeight }}>
                    <ReactMarkdown escapeHtml={false} source={this.state.copyright}/>
                </div>
            </div>
        )
    }
}
