import React from "react";

export default class Formspree extends React.Component {
	constructor(props) {
		super(props);
		this.submitForm = this.submitForm.bind(this);
		this.state = {
			status: "",
			formID: this.props.formspreeEndpoint,
			successMsg: this.props.successMessage,
			failMsg: this.props.errorMessage,
			messageboxPH: this.props.messageFieldPlaceholder,
			extraFields: this.props.extraFieldsNames,
			submitText: this.props.submitButtonText
		};
	}

	render() {
		const { status } = this.state;

		return (
			<form
				onSubmit={this.submitForm}
				action={this.state.formID}
				method="POST"
			>
				<input required type="email" name="Email" placeholder="Email Address *" />
				{ this.state.extraFields }
				<textarea name="Message" placeholder={this.state.messageboxPH}/>
			{
				status === "SUCCESS" ? 
				<p className="msg passed">
					{this.state.successMsg}
				</p> 
				: 
				<button>{this.state.submitText}</button>
			}
			{
				status === "ERROR" && 
				<p className="msg failed">
					{this.state.failMsg}
				</p>
			}
			</form>
		);
  	}

	submitForm(ev) {
		ev.preventDefault();
		const form = ev.target;
		const data = new FormData(form);
		const xhr = new XMLHttpRequest();
		xhr.open(form.method, form.action);
		xhr.setRequestHeader("Accept", "application/json");
		xhr.onreadystatechange = () => {
			if (xhr.readyState !== XMLHttpRequest.DONE) return;
			if (xhr.status === 200) {
				form.reset();
				this.setState({ status: "SUCCESS" });
			} else {
				this.setState({ status: "ERROR" });
			}
		};
		xhr.send(data);
	}
}