import React from "react";

const ReactMarkdown = require('react-markdown');

export default class FooterA extends React.Component{

    state = {
        footerColour: this.props.fields.footerColour?
            this.props.fields.footerColour : '',
        textColour: this.props.fields.textColour?
            this.props.fields.textColour : '',
        logoImage: this.props.fields.logoImage?
            this.props.fields.logoImage.fields.file.url: '',
        bottomText: this.props.fields.bottomText?
            this.props.fields.bottomText : '',
        textWeight: this.props.fields.textWeight?
            this.props.fields.textWeight : '400'
    }

    render(){
        return(
            <div className="row footer-a" style={{ backgroundColor: this.state.footerColour, color: this.state.textColour }}>
                <div className="col-12 logo-row">
                    <a href="/">
                        <img src={this.state.logoImage} alt="Logo" />
                    </a>
                </div>
                {
                    this.props.fields.linkText&& this.props.fields.linkText.map((text, i) => {
                        if(this.props.fields.linkUrl && this.props.fields.linkUrl[i]){
                            return (
                                <div className="col-12 link-row" style={{fontWeight: this.state.textWeight}} key={i}>
                                    <a href={this.props.fields.linkUrl[i]}>
                                        {text}
                                    </a>
                                </div>
                            )
                        }
                        return ""
                    })
                }
                <div className="col-12 social-row">
                    {
                        this.props.fields.socialMediaLogos&& this.props.fields.socialMediaLogos.map((logos, i) => {
                            if(this.props.fields.socialMediaUrl && this.props.fields.socialMediaUrl[i]){
                                return (
                                    <a href={this.props.fields.socialMediaUrl[i]} target="_blank" rel="noopener noreferrer" key={i}>
                                        <img src={logos.fields.file.url} alt="Logo" />
                                    </a>
                                )
                            }
                            return ""
                        })
                    }
                </div>
                <div className="col-12 text-row" style={{fontWeight: this.state.textWeight}}>
                    <ReactMarkdown escapeHtml={false} source={this.state.bottomText}/>
                </div>
            </div>
        );
    }
}
