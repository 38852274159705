import React from 'react';
import {validateProp} from './GlobalTools';

const ReactMarkdown = require('react-markdown');

export default class CustomRedirect extends React.Component{

    state = {
        instantRedirect: validateProp(this.props.fields.instantRedirect, 'No'),
        redirectUrl: validateProp(this.props.fields.redirectUrl),
        redirectMessage: validateProp(this.props.fields.redirectMessage),
        redirectTime: validateProp(this.props.fields.delayTime, 5)
    }

    componentDidMount(){
        if(this.state.instantRedirect==="Yes"){
            window.location.replace(this.state.redirectUrl);
        }else{
            setTimeout(() => {
                window.location.replace(this.state.redirectUrl);
            }, this.state.redirectTime*1000);
        }
    }

    render(){
        console.log(this.state.iframe)
        return(
            <div className='row custom-redirect'>
                <div className='col-8 redir-message'>
                    <ReactMarkdown source={this.state.redirectMessage} escapeHtml={false}/>
                </div>
            </div>
        )
    }
}
