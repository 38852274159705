import React from "react";
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

export default class NavigationBarA extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      text: props.fields.navbarText,
      link: props.fields.navbarLink ? props.fields.navbarLink : '',
      navbarTextColour: props.fields.navbarTextColour ? props.fields.navbarTextColour : '',
      navbarLogo: props.fields.navbarLogo ? props.fields.navbarLogo.fields.file.url : '',
      navbarLogoAlt: props.fields.navbarLogo ? props.fields.navbarLogo.fields.title : '',
      textWeight: props.fields.textWeight? props.fields.textWeight : '200',
      isClicked: false,
      transparent: props.fields.transparentBeforeScroll? props.fields.transparentBeforeScroll : 'No',
      navbarColour: props.fields.navbarColour ? (
        props.fields.transparentBeforeScroll==="Yes"?
          'transparent' : props.fields.navbarColour
      ) : 'white',
      navbarTopPadding: props.fields.navbarTopPadding ? props.fields.navbarTopPadding : 0,
      navbarColourBackup: props.fields.navbarColour ? props.fields.navbarColour : ''
    }
  }

  componentDidMount(){
    $(document).scroll((e) => {
        if(this.state.transparent==="Yes"){
            if($(window).scrollTop() === 0){
                this.setState({
                  navbarColour: 'transparent',
                  navbarTopPadding: this.props.fields.navbarTopPadding ?
                        this.props.fields.navbarTopPadding : ''
                })
            }else{
                this.setState({
                  navbarColour: this.props.fields.navbarColour ?
                        this.props.fields.navbarColour : '',
                  navbarTopPadding: 0
                })
            }
        }
    })
}

  clicked = () => {
    this.setState({
      isClicked: !this.state.isClicked
    })
  }

  render(){
      return(
          <div className="row navbar-a" style={{ backgroundColor: this.state.navbarColour, paddingTop: this.state.navbarTopPadding }}>
              <div className="col-2 navbar-logo">
                <a href="/">
                  <img src={this.state.navbarLogo} alt={this.state.navbarLogoAlt}/>
                </a>
              </div>

              {
                $(window).width() > 1024 ? (
                  this.state.text && this.state.text.map((texts, i) => {
                    if (i <= 9) {
                      return (
                        <div className={"col-1 navbar-menu " + (i === 0 ? "ml-auto" : "")} key={i} style={{
                          fontWeight: this.state.textWeight
                        }}>
                          <a href={this.state.link[i]} style={{ color: this.state.navbarTextColour}}>{texts}</a>
                        </div>
                      )
                    }
                    return "";
                  })
                ) : (
                  [
                    <div className="col-3 ml-auto navbar-menu-button" style={{ color: this.state.navbarTextColour}} onClick={this.clicked}>
                      {this.state.isClicked? <FontAwesomeIcon icon={faTimes}/> : <FontAwesomeIcon icon={faBars}/>}
                    </div>,
                    <div className="navbar-panel" hidden={!this.state.isClicked}
                      style={{backgroundColor: this.state.navbarColourBackup, color: this.state.navbarTextColour}}>
                      {
                        this.state.text && this.state.text.map((texts, i) => {
                          if (i <= 9) {
                            return (
                              <div className="row navbar-menu" key={i} style={{
                                fontWeight: this.state.textWeight
                              }}>
                                <a href={this.state.link[i]} style={{ color: this.state.navbarTextColour}}>{texts}</a>
                              </div>
                            )
                          }
                          return "";
                        })
                      }
                    </div>
                  ]
                )
              }
          </div>
      );
  }
}
