import React from "react";
import $ from 'jquery';

export default class FooterD extends React.Component{

    state = {
        backgroundColour: this.props.fields.backgroundColour ? this.props.fields.backgroundColour : 'white',
        textColour: this.props.fields.textColour ? this.props.fields.textColour : 'black',
        menuItemNames: this.props.fields.menuTextNames ? this.props.fields.menuTextNames : [],
        menuItemUrl: this.props.fields.menuTextUrl ? this.props.fields.menuTextUrl : [],
        socialMediaLogos: this.props.fields.socialMediaLogos ? this.props.fields.socialMediaLogos : [],
        socialMediaUrl: this.props.fields.socialMediaUrl ? this.props.fields.socialMediaUrl : [],
        copyrightText: this.props.fields.copyrightText ? this.props.fields.copyrightText : '',
        reverseOrder: this.props.fields.reverseOrder ? this.props.fields.reverseOrder : 'No',
        textWeight: this.props.fields.textWeight ? this.props.fields.textWeight : '400'
    }

    componentDidMount(){
        if (this.state.reverseOrder === 'Yes') {
            $(".footer-d").css('flexDirection', 'row-reverse');
            $(".block").width("200px");
        }
    }

    render(){
        return(
            <div className="row footer-d" style={{ backgroundColor: this.state.backgroundColour}}>
                <div className="col-4 social-media">
                    {
                        this.props.fields.socialMediaLogos && this.props.fields.socialMediaLogos.map((logos, i) => {
                            if (this.props.fields.socialMediaUrl && this.props.fields.socialMediaUrl[i] && i < 6) {
                                return (
                                    <a href={this.props.fields.socialMediaUrl[i]} key={i} target="_blank" rel="noopener noreferrer">
                                        <img src={logos.fields.file.url} alt={logos.fields.title} />
                                    </a>
                                )
                            }
                            return ""
                        })
                    }
                    <div className="copyright" style={{ color: this.state.textColour, fontWeight: this.state.textWeight }}>
                        {this.state.copyrightText}
                    </div>
                </div>

                <div className="block"></div>

                <div className="col-5 menu-item" style={{ color: this.state.textColour, fontWeight: this.state.textWeight }}>
                    {
                        this.props.fields.menuItemNames && this.props.fields.menuItemNames.map((text, i) => {
                            if (this.props.fields.menuItemUrl && this.props.fields.menuItemUrl[i] && i < 6) {
                                return (
                                    <div className="col-2 links" key={i}>
                                        <a href={this.props.fields.menuItemUrl[i]}>
                                            {text}
                                        </a>
                                    </div>
                                )
                            }
                            return ""
                        })
                    }
                </div>
            </div>
        );
    }
}
