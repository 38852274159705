import React from "react";
import $ from 'jquery';
const ReactMarkdown = require('react-markdown');


export default class CardAreaC extends React.Component{

    state = {
        text1: this.props.fields.text1 ? this.props.fields.text1 : '',
        text2: this.props.fields.text2 ? this.props.fields.text2 : '',
        text3: this.props.fields.text3 ? this.props.fields.text3 : '',
        textColour: this.props.fields.textColour ? this.props.fields.textColour : '',
        backgroundColour: this.props.fields.backgroundColour ? this.props.fields.backgroundColour : '',
        linkColour: this.props.fields.linkColour ? this.props.fields.linkColour : '',
        textWeight: this.props.fields.textWeight ? this.props.fields.textWeight : '400'
    }

    componentDidMount(){
        $('.card-area-c a').css('color', this.state.linkColour);
    }

    render(){
        return(
            <div className="row card-area-c" style={{ 
                backgroundColor: this.state.backgroundColour,
                fontWeight: this.state.textWeight
            }}>
                    <div className="col-4 text1" style={{ color: this.state.textColour }}>
                        <ReactMarkdown escapeHtml={false} source={this.state.text1}/>
                    </div>
                    <div className="col-4 text2" style={{ color: this.state.textColour }}>
                        <ReactMarkdown escapeHtml={false} source={this.state.text2}/>
                    </div>
                    <div className="col-4 text3" style={{ color: this.state.textColour }}>
                        <ReactMarkdown escapeHtml={false} source={this.state.text3}/>
                    </div>
            </div>
        )
    }
}
