import React from "react";
import ImageGallery from 'react-image-gallery';

export default class GalleryB extends React.Component {

    state = {
        images: [],
        image: this.props.fields.image,
        backgroundColour: this.props.fields.backgroundColour ? this.props.fields.backgroundColour : '',
        headerColour: this.props.fields.headerColour ? this.props.fields.headerColour : '',
        header: this.props.fields.header ? this.props.fields.header : '',
        showFullscreenButton: false,
        showThumbnails: false,
        showPlayButton: false,
        showBullets: true,
        autoPlay: this.props.fields.autoplay ? (this.props.fields.autoplay === "true" ? true : false) : false,
        slideInterval: this.props.fields.slideIntervals ? (this.props.fields.slideIntervals*1000) : 3000,
        headerWeight: this.props.fields.headerWeight ? this.props.fields.headerWeight : '600'
    }

    componentDidMount() {
        let temp =
        this.state.image && this.state.image.map((images, i) => {
            if (this.state.image[i]) {
                return {
                    original: this.state.image[i].fields.file.url,
                }
            }
            return {};
        });
        this.setState({
            images: temp
        })
    }

    render(){
        return(
            <div className="row gallery-b" style={{ backgroundColor: this.state.backgroundColour }}>
                <div className="col-6 offset-3">
                    <div className="header" style={{ color: this.state.headerColour, fontWeight: this.state.headerWeight }}>
                        {this.state.header}
                    </div>

                    <div className="image">
                        <ImageGallery
                            items={this.state.images}
                            showFullscreenButton={this.state.showFullscreenButton}
                            showThumbnails={this.state.showThumbnails}
                            showPlayButton={this.state.showPlayButton}
                            showBullets={this.state.showBullets}
                            autoPlay={this.state.autoPlay}
                            slideInterval={this.state.slideInterval}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
