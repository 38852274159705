import React from "react";
import $ from 'jquery';
import { createClient } from 'contentful-management'

var client = createClient({
    accessToken: process.env.REACT_APP_CF_MT
})

export default class GalleryC extends React.Component{

    sortPhotos = (imageArray) =>{
        return [
            imageArray.filter((e, i) => i%3===0),
            imageArray.filter((e, i) => i%3===1),
            imageArray.filter((e, i) => i%3===2)
        ]
    }

    joinPhotos = (imageArray) =>{
        return this.filterPhotos(imageArray[0].concat(imageArray[1].concat(imageArray[2])))
    }

    filterPhotos = (imageArray) => {
        return imageArray.sort((a,b) =>
            new Date(a.fields.file.timestamp) - new Date(b.fields.file.timestamp)
        ).slice(imageArray.length-this.state.maxIGImages,imageArray.length)
    }

    state = {
        header: this.props.fields.header ? this.props.fields.header : '',
        buttonText: this.props.fields.buttonText ? this.props.fields.buttonText : '',
        headerColour: this.props.fields.headerColour ? this.props.fields.headerColour : 'black',
        buttonTextColour: this.props.fields.buttonTextColour ? this.props.fields.buttonTextColour : 'black',
        image: this.props.fields.useInstagramPhotos === "Yes" ? (
            [[],[],[]]
        ) : (
            this.props.fields.image ?
                this.sortPhotos(this.props.fields.image.reverse()) : [[],[],[]]
        ),
        token: this.props.fields.testerToken ? this.props.fields.testerToken : '',
        buttonClicked: false,
        sectionStyle: {},
        lastAggregated: this.props.fields.lastAggregation? this.props.fields.lastAggregation : '',
        instagramPhotos : this.props.fields.instagramImages? this.props.fields.instagramImages : '',
        maxIGImages: this.props.fields.maxIGImages? this.props.fields.maxIGImages : 6,
        managementToken: process.env.REACT_APP_CF_MT,
        mobileImage: [],
        headerWeight: this.props.fields.headerWeight? this.props.fields.headerWeight : '400',
        buttonWeight: this.props.fields.buttonWeight? this.props.fields.buttonWeight : '400',
    }

    expand = () => {
        this.setState({
            sectionStyle:{
                maxHeight:"10000px"
            },
            buttonClicked: true
        })
    }

    loadImagesFromIG = async() => {
        if(this.state.token!==''){
            let firstAPICall = await fetch('https://graph.instagram.com/me/media?fields=id,caption&access_token='+this.state.token);
            let imageIDs = (await firstAPICall.json())['data'];
            let imageList = [];
            for(let i = 0; i < imageIDs.length; i++){
                let secondAPICall = await fetch('https://graph.instagram.com/'+(imageIDs[i]['id'])+'?fields=media_type,media_url,timestamp,permalink&access_token='+this.state.token);
                let imageURL = (await secondAPICall.json());
                let url = imageURL['media_url']
                let time = imageURL['timestamp']
                let type = imageURL['media_type']
                let perm = imageURL['permalink']
                if(type==='VIDEO') continue;
                imageList = [...imageList, {
                    fields:{
                        file:{
                            perm:perm,
                            url: url,
                            timestamp: time
                        }
                    }
                }]
            }
            this.setState({
                image: this.sortPhotos(this.filterPhotos(imageList).reverse()),
                mobileImage: this.filterPhotos(imageList).reverse()
            })
        }
    }

    componentDidMount() {
        if(this.props.fields.useInstagramPhotos==="Yes"){
            let lastAggregation = new Date(this.state.lastAggregated)
            let currentTime = new Date();
            let lastString = lastAggregation.getFullYear()+"-"+
                lastAggregation.getMonth()+"-"+
                lastAggregation.getDay()+"|"+
                lastAggregation.getHours();
            let thisString = currentTime.getFullYear()+"-"+
                currentTime.getMonth()+"-"+
                currentTime.getDay()+"|"+
                currentTime.getHours();

            if(lastString!==thisString){
                this.loadImagesFromIG().then(()=>{
                    console.log('Loading Images From Instagram...')

                    client.getSpace(process.env.REACT_APP_CF_SPACE)
                        .then((space) => space.getEnvironment('master'))
                        .then((env) => env.getEntry(this.props.sys.id))
                        .then((entry) => {
                            entry.fields.instagramImages['en-US'] = this.state.image
                            entry.fields.lastAggregation['en-US'] = currentTime.toISOString()
                            return entry.update()
                        })
                        .then((entry) => {
                            console.log(`Images Loaded and saved.`)
                            return entry.publish()
                        })
                        .catch(console.error)
                });
            }else{
                console.log('Loading Images from Contentful...')
                this.setState({
                    image: this.sortPhotos(this.joinPhotos(this.state.instagramPhotos).reverse()),
                    mobileImage: this.joinPhotos(this.state.instagramPhotos).reverse()
                })
            }
        }
    }

    render(){
        return(
            $(window).width()>1024 ?
                <div className="row gallery-c">
                    <div className="col-12 header" style={{ color: this.state.headerColour, fontWeight: this.state.headerWeight }}> {this.state.header} </div>
                    <div className="col-4 image-column" style={this.state.sectionStyle}>
                        {this.state.image[0].map((image, i)=>
                            <a target="_blank" rel="noopener noreferrer" href={image.fields && image.fields.file.perm && image.fields.file.perm} key={i}>
                                <img src={image.fields && image.fields.file.url && image.fields.file.url} alt={image.fields.title} />
                            </a>
                        )}
                    </div>
                    <div className="col-4 image-column" style={this.state.sectionStyle}>
                        {this.state.image[1].map((image, i)=>
                            <a target="_blank" rel="noopener noreferrer" href={image.fields && image.fields.file.perm && image.fields.file.perm} key={i}>
                                <img src={image.fields && image.fields.file.url && image.fields.file.url} alt={image.fields.title} />
                            </a>
                        )}
                    </div>
                    <div className="col-4 image-column" style={this.state.sectionStyle}>
                        {this.state.image[2].map((image, i)=>
                            <a target="_blank" rel="noopener noreferrer" href={image.fields && image.fields.file.perm && image.fields.file.perm} key={i}>
                                <img src={image.fields && image.fields.file.url && image.fields.file.url} alt={image.fields.title} />
                            </a>
                        )}
                    </div>
                    <div className="col-12 load-more"
                        style={{ color: this.state.buttonTextColour, fontWeight: this.state.buttonWeight }}
                        onClick={this.expand}
                        hidden={this.state.buttonClicked}
                    >
                        <span>{this.state.buttonText}</span>
                    </div>
                </div>
                :
                <div className="row gallery-c">
                    <div className="col-12 header" style={{ color: this.state.headerColour, fontWeight: this.state.headerWeight }}> {this.state.header} </div>
                    <div className="col-12 image-column" style={this.state.sectionStyle}>
                        {this.state.mobileImage.map((image, i)=>
                            <a target="_blank" rel="noopener noreferrer" href={image.fields && image.fields.file.perm && image.fields.file.perm} key={i}>
                                <img src={image.fields && image.fields.file.url && image.fields.file.url} alt={image.fields.title} />
                            </a>
                        )}
                    </div>
                    <div className="col-12 load-more"
                        style={{ color: this.state.buttonTextColour, fontWeight: this.state.buttonWeight }}
                        onClick={this.expand}
                        hidden={this.state.buttonClicked}
                    >
                        <span>{this.state.buttonText}</span>
                    </div>
                </div>
        );
    }
}
