import React from "react";
import $ from 'jquery';

const ReactMarkdown = require('react-markdown');

export default class GridAreaA extends React.Component{

    state = {
        text: this.props.fields.textCell? this.props.fields.textCell : "",
        image: this.props.fields.imageCell? this.props.fields.imageCell.fields.file.url : "",
        textColour: this.props.fields.textColour? this.props.fields.textColour : "black",
        backgroundColour: this.props.fields.backgroundColour? this.props.fields.backgroundColour : "white",
        reverse: this.props.fields.reverseOrder? (this.props.fields.reverseOrder==="Yes"? true : false) : false,
        imageSize: this.props.fields.imageSize? (
            this.props.fields.imageSize==="Small" ? "7:5" : (
                this.props.fields.imageSize==="Large" ? "5:7" : "6:6"
            )
        ) : "6:6",
        textWeight: this.props.fields.textWeight? this.props.fields.textWeight : '400'
    }

    componentDidMount(){
        let textColour = this.state.textColour;
        if($(window).width() <= 1024) {
            $(".image-cell").height( $(".image-cell").width() );
            $(".text-cell").css("min-height", $(".text-cell").width());
        }else{
            $(".gridarea-a").css('min-height', $(".image-cell").width() / 1.5 );
        }
        
        $(".text-cell a").css({
            "border":"1px solid "+textColour
        });
    }

    render(){
        return(
            <div className="row gridarea-a" 
                style={this.state.reverse? {flexDirection: ($(window).width() > 1000? "row":"column") + "-reverse"} : {}}>
                <div className={"col-"+this.state.imageSize.split(":")[0]+" text-cell"} 
                    style={{
                        backgroundColor:this.state.backgroundColour,
                        color: this.state.textColour,
                        fontWeight: this.state.textWeight
                    }}
                >
                    <ReactMarkdown source={this.state.text} escapeHtml={false} />
                </div>
                <div className={"col-"+this.state.imageSize.split(":")[1]+" image-cell"} 
                    style={{backgroundImage:"url("+this.state.image+")"}}
                ></div>
            </div>
        );
    }
}
