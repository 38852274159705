import React from "react";
import $ from 'jquery';

const ReactMarkdown = require('react-markdown');

export default class CardAreaB extends React.Component{

    state = {
        image: this.props.fields.cardBackground ? this.props.fields.cardBackground : '',
        header: this.props.fields.header ? this.props.fields.header : '',
        subHeader: this.props.fields.subHeader ? this.props.fields.subHeader : '',
        textArea1: this.props.fields.textArea1 ? this.props.fields.textArea1 : '',
        textArea2: this.props.fields.textArea2 ? this.props.fields.textArea2 : '',
        textArea3: this.props.fields.textArea3 ? this.props.fields.textArea3 : '',
        headerSubHeaderColour: this.props.fields.headerSubHeaderColour ? this.props.fields.headerSubHeaderColour : 'black',
        textAreaColour: this.props.fields.textAreaColour ? this.props.fields.textAreaColour : 'black',
        backgroundColour: this.props.fields.backgroundColour ? this.props.fields.backgroundColour : 'white',
        textWeight: this.props.fields.textWeight ? this.props.fields.textWeight : '400',
        headerWeight: this.props.fields.headerWeight ? this.props.fields.headerWeight : '600',
        subHeaderWeight: this.props.fields.subHeaderWeight ? this.props.fields.subHeaderWeight : '200',
        addDarkeningOverlay1: this.props.fields.addDarkeningOverlay1 ? this.props.fields.addDarkeningOverlay1 : 'No',
        addDarkeningOverlay2: this.props.fields.addDarkeningOverlay2 ? this.props.fields.addDarkeningOverlay2 : 'No',
        addDarkeningOverlay3: this.props.fields.addDarkeningOverlay3 ? this.props.fields.addDarkeningOverlay3 : 'No'
    }

    componentDidMount() {
        if (this.state.addDarkeningOverlay1 === "Yes") {
            $('#i0').css('background-color', 'rgba(0,0,0,0.5)')
        }
        if (this.state.addDarkeningOverlay2 === "Yes") {
            $('#i1').css('background-color', 'rgba(0,0,0,0.5)')
        }
        if (this.state.addDarkeningOverlay3 === "Yes") {
            $('#i2').css('background-color', 'rgba(0,0,0,0.5)')
        }
    }

    render(){
        return(
            <div className="row card-area-b" style={{ backgroundColor: this.state.backgroundColour }}>
                <div className="col-12 header-overlay" style={{ color: this.state.headerSubHeaderColour }}>
                    <div className="header" style={{fontWeight: this.state.headerWeight}}> {this.state.header} </div>
                    <div className="subHeader" style={{fontWeight: this.state.subHeaderWeight}}> {this.state.subHeader} </div>
                </div>
                {
                    this.state.image.map((image, i) => {
                        if (i < 3 && this.state.image[i]) {
                            return (
                                <div className="col-3 box" style={{ backgroundImage: "url(" + this.state.image[i].fields.file.url + ")" }} key={i}>
                                    <div className="cover" id={"i" + i} style={{ color: this.state.textAreaColour, fontWeight: this.state.textWeight }}>
                                        <ReactMarkdown source={i === 0 ? this.state.textArea1 : (i === 1 ? this.state.textArea2 : this.state.textArea3 ) } escapeHtml={false}/>
                                    </div>
                                </div>
                            )
                        }
                        return ""
                    })
                }
            </div>
        );
    }
}
