import React from 'react';
import $ from 'jquery';
import {validateProp} from '../GlobalTools';

const ReactMarkdown = require('react-markdown');

export default class TextAreaC extends React.Component {

    state = {
        sectionID: Math.floor(Math.random() * 1001),
        backgroundColour: validateProp(this.props.fields.backgroundColour),
        fontSize: validateProp(this.props.fields.fontSize, '14'),
        quoteSize: validateProp(this.props.fields.quoteSize, '24'),
        fontColour: validateProp(this.props.fields.fontColour),
        textAlignment: validateProp(this.props.fields.textAlignment, 'left').toLowerCase(),
        imageSpan: validateProp(this.props.fields.imageSpan, 'full width').toLowerCase(),
        text: validateProp(this.props.fields.text),
        textWeight: validateProp(this.props.fields.textWeight, '400')
    }

    componentDidMount(){
        let innerText = $('#'+this.state.sectionID+' blockquote').html()
        $('#'+this.state.sectionID+' blockquote').css('font-size', this.state.quoteSize+'pt').html("\""+innerText+"\"");
        $('#'+this.state.sectionID+' blockquote > th').css('background-color', this.state.backgroundColour);

        if($(window).width()<1024){
            $('#'+this.state.sectionID+' img').css('width', 
                this.state.imageSpan==="half width"? '80%' : (
                    this.state.imageSpan==="third width"? '65%' : '100%'
                )
            )
        }else{
            $('#'+this.state.sectionID+' img').css('width', 
                this.state.imageSpan==="half width"? '50%' : (
                    this.state.imageSpan==="third width"? '33%' : '100%'
                )
            )
        }
        
        $('#'+this.state.sectionID+' ol').css('align-items', this.state.textAlignment==="center"? 'center' : (
            this.state.textAlignment==="right" ? 'flex-end' : 'flex-start' 
        ))
        $('#'+this.state.sectionID+' ul').css('align-items', this.state.textAlignment==="center"? 'center' : (
            this.state.textAlignment==="right" ? 'flex-end' : 'flex-start' 
        ))

        $('#'+this.state.sectionID+' table').css('align-items', this.state.textAlignment==="center"? 'center' : (
            this.state.textAlignment==="right" ? 'flex-end' : 'flex-start' 
        ))
    }

    render() {
        return (
            <div id={this.state.sectionID} className="row text-area-c" style={{ backgroundColor: this.state.backgroundColour }}>
                <div className="col-8 offset-2 text-section" 
                    style={{
                        fontSize: this.state.fontSize+'pt',
                        color: this.state.fontColour,
                        textAlign: this.state.textAlignment,
                        fontWeight: this.state.textWeight
                    }}
                >
                    <ReactMarkdown escapeHtml={false} source={this.state.text}></ReactMarkdown>
                </div>
            </div>
        )
    }
}