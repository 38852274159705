import React from 'react'
import $ from 'jquery';

const ReactMarkdown = require('react-markdown');

export default class MapAreaC extends React.Component {
    state = {
        backgroundColour: this.props.fields.backgroundColour ? this.props.fields.backgroundColour : 'white',
        text: this.props.fields.text ? this.props.fields.text : '',
        textColour: this.props.fields.textColour ? this.props.fields.textColour : 'black',
        linkColour: this.props.fields.linkColour ? this.props.fields.linkColour : 'blue',
        embedded: this.props.fields.googleMapsEmbeddedCode ? this.props.fields.googleMapsEmbeddedCode : '',
        id: 'i'+Math.floor(Math.random() * 100),
        header: this.props.fields.header ? this.props.fields.header : '',
        headerWeight: this.props.fields.headerWeight ? this.props.fields.headerWeight : '400',
        textWeight: this.props.fields.textWeight ? this.props.fields.textWeight : '200'
    }

    componentDidMount(){
        $('.maparea-c .map').height( $('.maparea-c .map').width() );
        $('#'+this.state.id+" a").css('color', this.state.linkColour)
    }

    render() {
        return (
            <div id={this.state.id} className="row maparea-c" style={{
                backgroundColor: this.state.backgroundColour,
                color: this.state.textColour
            }}>
                <div className="row title" style={{fontWeight: this.state.headerWeight}}>
                    {this.state.header}
                </div>
                <div className="col-4 text" style={{fontWeight: this.state.textWeight}}>
                    <ReactMarkdown escapeHtml={false} source={this.state.text}/>
                </div>
                <div className="col-4 map" >
                    <ReactMarkdown escapeHtml={false} source={this.state.embedded}/>
                </div>
            </div>
        )
    }
}
