import React from "react";

const ReactMarkdown = require('react-markdown');

export default class GridAreaB extends React.Component {

    state = {
        text1: this.props.fields.text1 ? this.props.fields.text1 : '',
        text2: this.props.fields.text2 ? this.props.fields.text2 : '',
        text3: this.props.fields.text3 ? this.props.fields.text3 : '',
        textColour: this.props.fields.textColour ? this.props.fields.textColour : 'white',
        images: this.props.fields.images ? this.props.fields.images : '',
        backgroundColour: this.props.fields.backgroundColour ? this.props.fields.backgroundColour : 'white',
        textWeight: this.props.fields.textWeight ? this.props.fields.textWeight : '400'
    }

    render(){
        return(
            <div className="row grid-area-b" style={{backgroundColor: this.state.backgroundColour}}>
                {
                    this.state.images.map((image, i) => {
                        if (i <= 2 && this.state.images[i]) {
                            return (
                                <div className="col-4 grid" key={i} style={{ backgroundImage: "url(" + this.state.images[i].fields.file.url + ")" }}>
                                    <div className="cover" style={{ color: this.state.textColour, fontWeight: this.state.textWeight }}>
                                        <ReactMarkdown source={i === 0 ? this.state.text1 : (i === 1 ? this.state.text2 : this.state.text3 ) } escapeHtml={false}/>
                                    </div>
                                </div>
                            )
                        }
                        return ""
                    })
                }
            </div>
        );
    }
}
