import React from "react";
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const ReactMarkdown = require('react-markdown');

export default class BannerB extends React.Component{

    state = {
        bannerImages: this.props.fields.videoOrSlideshowBackground === "Slideshow" ?
            this.props.fields.background.filter((elem) => {
                return elem.fields.file.contentType.includes("image")
            }) : [],
        bannerVideos: this.props.fields.videoOrSlideshowBackground === "Video" ?
            this.props.fields.background.filter((elem) => {
                return elem.fields.file.contentType.includes("video")
            }) : [],
        videoOrImage: this.props.fields.videoOrSlideshowBackground, //this is a required field on contentful
        textColour: this.props.fields.textColour ?
            this.props.fields.textColour : 'white',
        text: this.props.fields.text ?
            this.props.fields.text : '',
        slideshowInterval: this.props.fields.slideshowInterval ?
            this.props.fields.slideshowInterval*1000 : 0,
        addBannerDarkeningOverlay: this.props.fields.addBannerDarkeningOverlay ? this.props.fields.addBannerDarkeningOverlay : 'No',
        addArrowIndication: this.props.fields.addArrowIndication ? this.props.fields.addArrowIndication : 'No',
        arrow: '',
        textWeight: this.props.fields.textWeight ? this.props.fields.textWeight : '200',
        rand: Math.random()*1000
    }

    componentDidMount(){
        if (this.state.slideshowInterval !== 0) {
            this.setState({
                bannerImages: this.state.bannerImages.map((image) => {
                    return image.fields.file.url
                })
            })

            setInterval(() => {
                if(this.state.videoOrImage==='Slideshow'){
                    let first = $('.image-container').children().first();
                    first.hide().remove();
                    $('.image-container').append(first).children().last().fadeIn('slow');
                }
            }, this.state.slideshowInterval)
        }
        else {
            this.setState({
                bannerImages: [this.state.bannerImages[0].fields.file.url]
            })
        }

        if (this.state.addBannerDarkeningOverlay === 'Yes') {
            $('.overlay').css('background-color', 'rgba(0,0,0,0.40)')
        }
        if (this.state.addArrowIndication === 'Yes') {
            this.setState({
                arrow: <FontAwesomeIcon icon={faChevronDown}/>
            })
        }
    }

    render(){
        return(
            <div className="row banner-b">
                <div className="image-container">
                {
                    this.state.videoOrImage==='Slideshow' &&
                    this.state.bannerImages.map((image, i) => {
                        return <img src={image} alt="Banner" key={i} />
                    })
                }
                </div>
                {
                    this.state.videoOrImage==='Video' &&
                    this.state.bannerVideos[0] &&
                    <video width="100%" autoPlay loop>
                        <source src={this.state.bannerVideos[0].fields.file.url}
                            type={this.state.bannerVideos[0].fields.file.contentType} />
                        Your Browser Does Not Support HTML Video
                    </video>
                }
                <div className="col-12 overlay">
                    <div className="slogan" style={{color: this.state.textColour, fontWeight: this.state.textWeight}}>
                        <ReactMarkdown escapeHtml={false} source={this.state.text}/>
                    </div>
                </div>
                <div className="arrow" style={{color: this.state.textColour}}>
                    {this.state.arrow}
                </div>
            </div>
        );
    }
}
