import React from "react";
import $ from 'jquery';
import {validateProp} from "../GlobalTools";

const ReactMarkdown = require('react-markdown');

export default class CardAreaA extends React.Component{

    state = {
        text: [
            validateProp(this.props.fields.text1),
            validateProp(this.props.fields.text2),
            validateProp(this.props.fields.text3)
        ],
        link: [
            validateProp(this.props.fields.link1),
            validateProp(this.props.fields.link2),
            validateProp(this.props.fields.link3)
        ],
        header: this.props.fields.header ? this.props.fields.header : '',
        images: this.props.fields.images ? this.props.fields.images : [],
        headerColour: this.props.fields.headerColour ? this.props.fields.headerColour : 'black',
        textColour: this.props.fields.textColour ? this.props.fields.textColour : 'black',
        backgroundColour: this.props.fields.backgroundColour ? this.props.fields.backgroundColour : 'white',
        randId1: '',
        randId2: '',
        onlyImage: false,
        textWeight: this.props.fields.textWeight? this.props.fields.textWeight : '400',
        headerWeight: this.props.fields.headerWeight? this.props.fields.headerWeight : '600',
        textAlign: validateProp(this.props.fields.textAlignment, 'left'),
        cardSize: validateProp(this.props.fields.cardSize, 'medium'),
        imageRatio: validateProp(this.props.fields.imageRatio, '1:1 (Square)') === '1:1 (Square)'?
            1 : (
                validateProp(this.props.fields.imageRatio, '1:1 (Square)') === '1:1.5 (Portrait)'?
                    1.5 : (
                        validateProp(this.props.fields.imageRatio, '1:1 (Square)') === '1.5:1 (Landscape)'?
                            0.667 : 1
                    )
            ),
    }

    componentDidMount(){
        let randId1 = Math.floor(Math.random() * 1000);
        let randId2 = Math.floor(Math.random() * 1000);

        this.setState({
            randId1: "i" + randId1,
            randId2: "e" + randId2
        });
    }

    componentDidUpdate() {
        if (this.state.header === '') {
            $('#' + this.state.randId2).css('padding', '0');
            
            if($('#' + this.state.randId1).prev().attr('class').includes('card-area-a')){
                $('#' + this.state.randId1).css('padding', '0 15px 50px');
            }
        }

        $('#'+this.state.randId1+" .card-area-container img").height( $('#' + this.state.randId1+" .card-area-container img").width() * this.state.imageRatio );
        $('#'+this.state.randId1+" p").css('text-align', this.state.textAlign);
    }

    render(){
        return(
            <div className="row card-area-a" id={this.state.randId1}
                style={
                    this.state.cardSize==="Medium"?
                    {backgroundColor: this.state.backgroundColour, margin: '0 10%'} : {backgroundColor: this.state.backgroundColour}
                }
            >
              <div className="col-12 card-area-header" id={this.state.randId2} style={{ color: this.state.headerColour, fontWeight: this.state.headerWeight }}>
                 {this.state.header}
              </div>
                {
                    this.state.images.map((images, i) => {
                        if (i <= 2 && this.state.images[i]) {
                            return (
                                <div key={i} className={
                                        (
                                            this.state.cardSize==="Small"?
                                                'col-2'+(i===0?' offset-3':''):'col-4'
                                        )+" card-area-container"
                                     }
                                     style={{
                                        color: this.state.textColour,
                                        fontWeight: this.state.textWeight
                                }}>
                                    {
                                        this.state.link[i]!=='' ?
                                            <a href={this.state.link[i]}
                                            >
                                                <img src={this.state.images[i].fields.file.url} 
                                                    alt={this.state.images[i].fields.title} key={1}
                                                    style={this.state.text[i]===''?{paddingBottom:'50px'}:{}}
                                                />
                                                <ReactMarkdown
                                                    source={this.state.text[i]}
                                                    escapeHtml={false} key={2}
                                                />
                                            </a>
                                        :
                                            [
                                                <img src={this.state.images[i].fields.file.url} 
                                                    alt={this.state.images[i].fields.title} key={1}
                                                    style={this.state.text[i]===''?{paddingBottom:'50px'}:{}}
                                                />,
                                                <ReactMarkdown source={this.state.text[i]} escapeHtml={false} key={2}/>
                                            ]
                                    }
                                </div>
                            )
                        }
                        return ""
                    })
                }
            </div>
        );
    }
}
