import React from "react";

import {TinyButton as ScrollUpButton} from "react-scroll-up-button";

export default class FooterB extends React.Component{

    state = {
        text: this.props.fields.text,
        link: this.props.fields.link ? this.props.fields.link : '',
        textColour: this.props.fields.textColour ? this.props.fields.textColour : '',
        backgroundColour: this.props.fields.backgroundColour ? this.props.fields.backgroundColour : '',
        textWeight: this.props.fields.textWeight ? this.props.fields.textWeight : '400'
    }

    render(){
        return(
            <div className="row footer-b" style={{ backgroundColor: this.state.backgroundColour, fontWeight: this.state.textWeight }}>
                {
                    this.state.text && this.state.text.map((text, i) => {
                        if (this.state.link[i] && i <= 7){
                            return ( i <= 6 &&  i < this.state.link.length-1 ?
                                <a className="col-1 text" key={i} href={this.state.link[i]} style={{ color: this.state.textColour }}>
                                    {text}
                                </a>
                                :
                                <a className="col-1 text" key={i} href={this.state.link[i]} style={{ borderRight: 0, color: this.state.textColour }}>
                                    {text}
                                </a>
                            )
                        }
                        return ""
                    })
                }
                <ScrollUpButton
                    StopPosition={0}
                    ShowAtPosition={300}
                    AnimationDuration={500}
                />
            </div>
        );
    }
}
