import React from 'react';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
const ReactMarkdown = require('react-markdown');

export default class OpenLP extends React.Component {
    
    state = {
        //Page 1
        page1WelcomeMessage:this.props.fields.brochure.fields.page1WelcomeMessage,
        page1BrochureDate:this.props.fields.brochure.fields.page1BrochureDate,
        page1MissionStatement:this.props.fields.brochure.fields.page1MissionStatement,

        //Page 2
        page2Logo:this.props.fields.brochure.fields.page2Logo,
        page2TopSection:this.props.fields.brochure.fields.page2TopSection,
        page2SocialIcons:this.props.fields.brochure.fields.page2SocialIcons,
        page2SocialHandles:this.props.fields.brochure.fields.page2SocialHandles,
        page2BottomSection:this.props.fields.brochure.fields.page2BottomSection,

        //Page 3
        page3OrderOfWorship:this.props.fields.brochure.fields.page3OrderOfWorship,

        //Page 4
        page4CallToWorship:this.props.fields.brochure.fields.page4CallToWorship,
        page4HeidelbergCatechism:this.props.fields.brochure.fields.page4HeidelbergCatechism,

        //Page 5
        page5PraiseAndPrayer:this.props.fields.brochure.fields.page5PraiseAndPrayer,
        page5Doxology:this.props.fields.brochure.fields.page5Doxology,
        page5ScriptureReading:this.props.fields.brochure.fields.page5ScriptureReading,
        page5Sermon:this.props.fields.brochure.fields.page5Sermon,
        page5ReflectionAndResponse:this.props.fields.brochure.fields.page5ReflectionAndResponse,
        page5OfferingPrayer:this.props.fields.brochure.fields.page5OfferingPrayer,

        //Page 6
        page6ChurchAnnouncements:this.props.fields.brochure.fields.page6ChurchAnnouncements,
        page6LordsPrayer:this.props.fields.brochure.fields.page6LordsPrayer,

        slideName:this.props.fields.name,
        contents:this.props.fields.slideContents,
        path:this.props.fields.key,
        basefont:this.props.fields.baseFontSizeVw,
        splitByTwos: this.props.fields.splitByTwos
    }

    urlParams = new URLSearchParams(window.location.search);
    path = this.urlParams.get('path');

    componentDidMount(){
    }

    insertBrochure(text, path){
        let start = text.indexOf("{");
        let end = text.indexOf('}');
        let field = text.substr(start+1, end-1);
        
        if(path.includes("welcome")){
            console.log("<span style='font-size:1.5vw'> "+this.state.page1BrochureDate+" </span>")
            return text.replace("{page1WelcomeMessage}", documentToHtmlString(this.state.page1WelcomeMessage))
                       .replace("{page1BrochureDate}", "<div style='font-size:1.8vw'> "+this.state.page1BrochureDate+" </div>");
        }

        if(path.includes("ctw")){
            let ctw = documentToHtmlString(this.state[field]);
            if(this.state.splitByTwos){
                let split = ctw.split("\n");
                let len = split.length;
                if(len <= 1){
                    split = ctw.split("</p><p>");
                    len = split.length;
                    split[0] = split[0].replace("<p>","");
                    split[len-1] = split[len-1].replace("</p>","");
                }
                for(var i = 1; i < len; i++){
                    if(this.path.includes(i)){
                        if(i === 1){
                            let first = split.slice((i*2)-1, (i*2)+1).join("<br><br>");
                            let text = split[0]+"<br>"+split[len-1]+"<br><br>"+first;
                            console.log(first, text, path);
                            return text.replace("{"+field+"}", text);
                        }else{
                            return text.replace("{"+field+"}", split.slice((i*2)-1, (i*2)+1).join("<br><br>"));
                        }
                    }
                    
                }
                console.log(split);
            }else{
                return text.replace("{"+field+"}", ctw);
            }
            console.log(ctw);
        }

        if(path.includes("hc")){
            let hc = documentToHtmlString(this.state[field]);
            return text.replace("{"+field+"}", hc);
        }

        if(path.includes("sm")){
            return text.replace("{page5Sermon}", "<b>"+documentToHtmlString(this.state.page5Sermon.content[1])+"</b>")
                       .replace("{page5ScriptureReading}", documentToHtmlString(this.state.page5ScriptureReading.content[1]));
        }

        if(path.includes("announce")){
            let announce = documentToHtmlString(this.state[field]);
            if(this.state.splitByTwos){
                let split = announce.split("</p><p>");
                let len = split.length;
                for(var i = 1; i < len; i++){
                    if(this.path.includes(i)){
                        if(i === 1){
                            let first = split.slice((i*6)-5, (i*6)+1).join("<br>");
                            let text = split[0]+"<br>"+first;
                            return text.replace("{"+field+"}", text.replace("<p>", "").replace("</p>", ""));
                        }else{
                            let text = split.slice((i*6)-5, (i*6)+1).join("<br>");
                            return text.replace("{"+field+"}", text.replace("<p>", "").replace("</p>", ""));
                        }
                    }
                }
            }else{
                return text.replace("{"+field+"}", announce);
            }
        }
        
        return text;
    }

    render(){
        return (
            <div id={this.state.path} className='openLP' style={{display: this.path.includes(this.state.path) ? "flex":"none", fontSize: this.state.basefont+"vw"}}>
                <ReactMarkdown escapeHtml={false} source={this.insertBrochure(this.state.contents, this.state.path)}></ReactMarkdown>
            </div>
        );
    }
}