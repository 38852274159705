import React from 'react'

const ReactMarkdown = require('react-markdown');

export default class BannerC extends React.Component {
    state = {
        text: this.props.fields.text ? this.props.fields.text : '',
        textColour: this.props.fields.textColour ? this.props.fields.textColour : '#000000',
        bannerImage: this.props.fields.image.fields.file.url ? this.props.fields.image.fields.file.url : '',
        textWeight: this.props.fields.textWeight ? this.props.fields.textWeight : '400'
    }

    render() {
        return (
            <div className = "row banner-c">
                <div className = "image" style = {{ backgroundImage: "url(" + this.state.bannerImage + ")" }}>
                    <div className = "overlay">
                        <div className = "banner-text" style = {{
                            color: this.state.textColour,
                            fontWeight: this.state.textWeight
                        }}>
                            <ReactMarkdown escapeHtml = {false} source = {this.state.text}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
