import React from "react";

const ReactMarkdown = require('react-markdown');

export default class TextAreaA extends React.Component{

    state = {
        textAreaText: this.props.fields.textAreaText ? this.props.fields.textAreaText : '' ,
        textAreaImage: this.props.fields.textAreaImage ? this.props.fields.textAreaImage.fields.file.url : ' ',
        textAreaImageAlt: this.props.fields.textAreaImage ? this.props.fields.textAreaImage.fields.title : '',
        textAreaAlignment: this.props.fields.textAreaAlignment ? this.props.fields.textAreaAlignment : '',
        textAreaImageWidth: this.props.fields.textAreaImageWidth ? this.props.fields.textAreaImageWidth : '',
        textAreaTextColour: this.props.fields.textAreaTextColour ? this.props.fields.textAreaTextColour : '',
        textAreaBackgroundColour: this.props.fields.textAreaBackgroundColour ? this.props.fields.textAreaBackgroundColour : '',
        textWeight: this.props.fields.textWeight? this.props.fields.textWeight : '300'
    }

    render(){
        return(
            <div className="row text-area-a" style={{ backgroundColor: this.state.textAreaBackgroundColour }}>
                <div className="col-4 offset-4 text-area"> 
                    <div className="text-area-text" style={{ 
                        textAlign: this.state.textAreaAlignment, 
                        color: this.state.textAreaTextColour,
                        fontWeight: this.state.textWeight
                    }}>
                        <ReactMarkdown escapeHtml={false} source={this.state.textAreaText}/>
                    </div>

                    <div className="text-area-image" style={{ display: (this.state.textAreaImage === ' ' ? "none" : "") }}>
                        <img src={this.state.textAreaImage} alt={this.state.textAreaImageAlt} style={{ width: this.state.textAreaImageWidth + "%" }}/>
                    </div> 
                </div>
            </div>
        );
    }
}
