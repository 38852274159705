import React from "react";

const ReactMarkdown = require('react-markdown');

export default class MapAreaA extends React.Component{

    state = {
        embedded: this.props.fields.googleMapsEmbeddedCode ? this.props.fields.googleMapsEmbeddedCode : ''
    }

    render(){
        return(
            <div className="row map-area-a">
                <ReactMarkdown escapeHtml={false} source={this.state.embedded}/>
            </div>
        );
    }
}
