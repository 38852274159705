import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

export default class Brochure extends React.Component {
    
    state = {
        //Page 1
        welcome:this.props.fields.page1WelcomeMessage,
        brochureDate:this.props.fields.page1BrochureDate,
        mission:this.props.fields.page1MissionStatement,

        //Page 2
        logoIcon:this.props.fields.page2Logo,
        getInTouch:this.props.fields.page2TopSection,
        socialIcons:this.props.fields.page2SocialIcons,
        socialHandles:this.props.fields.page2SocialHandles,
        address:this.props.fields.page2BottomSection,

        //Page 3
        oow:this.props.fields.page3OrderOfWorship,

        //Page 4
        ctw:this.props.fields.page4CallToWorship,
        hc:this.props.fields.page4HeidelbergCatechism,

        //Page 5
        pp:this.props.fields.page5PraiseAndPrayer,
        dox:this.props.fields.page5Doxology,
        scripture:this.props.fields.page5ScriptureReading,
        sermon:this.props.fields.page5Sermon,
        ref:this.props.fields.page5ReflectionAndResponse,
        off:this.props.fields.page5OfferingPrayer,

        //Page 6
        ca:this.props.fields.page6ChurchAnnouncements,
        lp:this.props.fields.page6LordsPrayer
    }

    options = {
        preserveWhitespace: true,
    };

    render(){
        return (
            <div className='brochure'>
                <div className='pages grp1'>
                    <div className='page page1' 
                        style={{
                            backgroundImage:'url(https://images.ctfassets.net/i9rifi7heelg/4Qim1R6OC3xjJ1iZjQ7Fto/1e54d733b12810eb769a98523cc804a8/sgfbg.jpg)',
                            backgroundPosition: '0',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat'
                        }}
                    >
                        <div className='welcome'>
                            {documentToReactComponents(this.state.welcome, this.options)}
                            <div className='date'>
                                {this.state.brochureDate}
                            </div>
                        </div>
                        <div className='mission'>
                            {documentToReactComponents(this.state.mission, this.options)}
                        </div>
                    </div>
                    <div className='page page2'>
                        <div className='icon'>
                            <img src={this.state.logoIcon.fields.file.url}/>
                        </div>
                        <div className='getInTouch'>
                            {documentToReactComponents(this.state.getInTouch, this.options)}
                        </div>
                        <div className='socials'>
                            {
                                this.state.socialHandles.length === this.state.socialIcons.length ?
                                this.state.socialHandles.map((handle, i) => {
                                    return <div key={"soc"-i} className='social'>
                                        <img className='icon' src={this.state.socialIcons[i].fields.file.url}/>
                                        <div className='handle'>{handle}</div>
                                    </div>
                                }) : ""
                            }
                        </div>
                        <div className='address'>
                            {documentToReactComponents(this.state.address, this.options)}
                        </div>
                    </div>
                </div>
                <div className='pages grp2'>
                    <div className='page page3'>
                        {documentToReactComponents(this.state.oow, this.options)}
                    </div>
                    <div className='page page4'>
                        <div className='ctw'>
                            {documentToReactComponents(this.state.ctw, this.options)}
                        </div>
                        <div className='hc'>
                            {documentToReactComponents(this.state.hc, this.options)}
                        </div>
                    </div>
                </div>
                <div className='pages grp3'>
                    <div className='page page5'>
                        <div className='pp'>
                            {documentToReactComponents(this.state.pp, this.options)}
                        </div>
                        <div className='dox'>
                            {documentToReactComponents(this.state.dox, this.options)}
                        </div>
                        <div className='scripture'>
                            {documentToReactComponents(this.state.scripture, this.options)}
                        </div>
                        <div className='sermon'>
                            {documentToReactComponents(this.state.sermon, this.options)}
                        </div>
                        <div className='ref'>
                            {documentToReactComponents(this.state.ref, this.options)}
                        </div>
                        <div className='off'>
                            {documentToReactComponents(this.state.off, this.options)}
                        </div>
                    </div>
                    <div className='page page6'>
                        <div className='ca'>
                            {documentToReactComponents(this.state.ca, this.options)}
                        </div>
                        <div className='lp'>
                            {documentToReactComponents(this.state.lp, this.options)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}