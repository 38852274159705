import React from "react";

const ReactMarkdown = require('react-markdown');

export default class BannerA extends React.Component{

    state = {
        text: this.props.fields.bannerButtonText,
        link: this.props.fields.bannerButtonLink ? this.props.fields.bannerButtonLink : '',
        bannerImage: this.props.fields.bannerImage ? this.props.fields.bannerImage.fields.file.url : '',
        bannerImageAlt: this.props.fields.bannerImage ? this.props.fields.bannerImage.fields.title : '',
        bannerSlogan: this.props.fields.bannerSlogan ? this.props.fields.bannerSlogan : '',
        bannerText: this.props.fields.bannerText ? this.props.fields.bannerText : '',
        bannerButtonText: this.props.fields.bannerButtonText ? this.props.fields.bannerButtonText : '',
        bannerSloganColour: this.props.fields.bannerSloganColour ? this.props.fields.bannerSloganColour : '',
        bannerTextColour: this.props.fields.bannerTextColour ? this.props.fields.bannerTextColour : '',
        sloganWeight: this.props.fields.sloganWeight ? this.props.fields.sloganWeight : '600',
        textWeight: this.props.fields.textWeight ? this.props.fields.textWeight : '400'
    }

    render(){
        return(
            <div className="row banner-a" style={{ backgroundImage: "url(" + this.state.bannerImage + ")" }}>
                <div className="col-6 offset-3 banner-overlay">
                  <div className="banner-slogan" style={{
                    color: this.state.bannerSloganColour,
                    fontWeight: this.state.sloganWeight
                  }}>
                    <ReactMarkdown escapeHtml={false} source={this.state.bannerSlogan.slice(0, 50)}/>
                  </div>
                  <div className="banner-text" style={{
                    color: this.state.bannerTextColour,
                    fontWeight: this.state.textWeight
                  }}>
                    <ReactMarkdown escapeHtml={false} source={this.state.bannerText}/>
                  </div>

                  <div className="banner-button-container">
                    {
                      this.state.text && this.state.text.map((texts, i) => {
                        if (i < 3 && this.state.link[i]) {
                          return (
                            <a href={this.state.link[i]} key={i} className="banner-button">
                              <div className={"banner-button-text"} style={{
                                color: this.state.bannerTextColour,
                                border: "2px solid " + this.state.bannerTextColour,
                                fontWeight: this.state.textWeight,
                                borderWidth: this.state.textWeight/100
                              }}>
                                {texts}
                              </div>
                            </a>
                          )
                        }
                        return "";
                      })
                    }
                  </div>
                </div>
            </div>
        );
    }
}
