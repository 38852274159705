import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router, Route} from "react-router-dom";
import Page from "./page";
const contentful = require('contentful');

class App extends React.Component{
    state = {
        data: []
    }

    client = contentful.createClient({
        space: process.env.REACT_APP_CF_SPACE,
        accessToken: process.env.REACT_APP_CF_ACCESS_TOKEN
    })

    componentDidMount(){
        this.client.getEntries({
            content_type:'page',
            limit: 1000,
            include: 3
        }).then((response) => {
            this.setState({
                data: response.items
            })
        })
    }

    render(){
        return(
            <Router>
                {
                    this.state.data.map((page, i) => {
                        return <Route key={"route-"+i} exact path={page.fields.path} component={() => <Page fields={page.fields}/>}/>
                    })
                }
            </Router>
        )
    }
}

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.unregister();
