import React from 'react';
import $ from 'jquery';
import Formspree from '../Formspree';

const ReactMarkdown = require('react-markdown');

export default class FormAreaB extends React.Component{

    state={
        backgroundColour: this.props.fields.backgroundColour ?
            this.props.fields.backgroundColour : 'white',
        textColour: this.props.fields.textColour ?
            this.props.fields.textColour : 'black',
        image: this.props.fields.image ?
            this.props.fields.image.fields.file.url : '',
        reverse: this.props.fields.reverse ?
            (this.props.fields.reverse==='Yes' ? true : false) : false,
        endpoint: this.props.fields.formspreeEndpoint?
            this.props.fields.formspreeEndpoint : '',
        formTitle: this.props.fields.formTitle ?
            this.props.fields.formTitle : '',
        formParagraph: this.props.fields.formParagraph ?
            this.props.fields.formParagraph : '',
        sMsg: this.props.fields.formSuccessMessage ?
            this.props.fields.formSuccessMessage : 'Message Sent!',
        fMsg: this.props.fields.formFailMessage ?
            this.props.fields.formFailMessage : 'Message Failed to Send.',
        ph: this.props.fields.formMessageBoxPlaceholder ?
            this.props.fields.formMessageBoxPlaceholder : 'Message',
        bt: this.props.fields.formSubmitButtonText ?
            this.props.fields.formSubmitButtonText : 'Send',
        extraFieldsNames: this.props.fields.extraFieldsNames ?
            this.props.fields.extraFieldsNames : [],
        textWeight: this.props.fields.textWeight ?
            this.props.fields.textWeight : '200',
        headerWeight: this.props.fields.headerWeight ?
            this.props.fields.headerWeight : '400',
        required: this.props.fields.required ?
            this.props.fields.required : []

    }

    componentDidMount(){
        if(this.state.reverse && this.state.image){
            let temp = $('.form-area-b').children().first();
            temp.remove();
            $('.form-area-b').append(temp);
        }
    }

    render(){
        return(
            <div className="row form-area-b" style={{
                backgroundColor: this.state.backgroundColour,
                color: this.state.textColour,
                fontWeight: this.state.textWeight
            }}>
                {
                    this.state.image !== '' &&
                    <div className="col-7 image" style={{
                        backgroundImage: "url("+this.state.image+")"
                    }}></div>
                }
                {
                    this.state.endpoint &&
                    <div className={"col-5 form"}>
                        <div className="title" style={{
                            fontWeight: this.state.headerWeight
                        }}> {this.state.formTitle} </div>
                        <ReactMarkdown escapeHtml={false} source={this.state.formParagraph}/>
                        <Formspree
                            formspreeEndpoint={this.state.endpoint}
                            successMessage={this.state.sMsg}
                            errorMessage={this.state.fMsg}
                            messageFieldPlaceholder={this.state.ph + " *"}
                            submitButtonText={this.state.bt}
                            extraFieldsNames={
                                this.state.extraFieldsNames.map((fields, i)=>{
                                    return <input type="text" key={i} name={fields} 
                                        placeholder={fields + (this.state.required[i] === "Yes" ? " *" : '')} 
                                        required={this.state.required[i] === "Yes"} 
                                        style={{color: this.state.textColour}}
                                    />
                                })
                            }
                        />
                        <span>* Required Fields</span>
                    </div>
                }
            </div>
        )
    }
}
